import React, { useContext } from 'react';
import { ShopProduct, ProductData, ShopProductVariant } from '../lib/shopify/types';
import { addItems } from './actions';
import { ActionType, CartContext } from '../context/CartContext';
import classNames from 'classnames';
import i18n from 'i18next';
import ShopIcon from '../../Common/Icons/ShopIcon';
import {
  getAllShopifyGlobalIdsFromProductItems,
  getShopProductsConditionNew,
  getShopProductsByVariantGids,
  getUniqueShopProductVariantsFromShopProducts
} from '../lib/shopify/leisterHelpers';
import ShopIcon24 from '../../Common/Icons/ShopIcon24';
import { MASTER_PRODUCT_ENDPOINT } from '../../Product/productendpoint';
import { getProduct } from '../../Product/productItem';
import Cookies from 'js-cookie';
import { COUNTRY_CODE } from '../../Common/CookieSettings/CookieConstants';
import AddToCartSlideOver from '../AddToCartSlideOver';
import { ProductItem } from '../../Product/types';

function SubmitButton({
  availableForSale,
  shopProductVariantId,
  productData,
  quantity = 1,
  hideButtonText,
  ghostButton,
  productName,
  language,
  siteName,
  masterProductPageUrl,
  productItem,
  productItemUrl,
  isUsedProduct,
  onAddToCart
}: {
  availableForSale: boolean;
  shopProductVariantId?: string;
  productData?: ProductData;
  quantity: number;
  hideButtonText: boolean;
  ghostButton: boolean;
  productName?: string;
  language?: string;
  siteName?: string;
  masterProductPageUrl?: string;
  productItem?: ProductItem;
  productItemUrl?: string;
  isUsedProduct?: boolean;
  onAddToCart?: () => void;
}) {
  const { dispatch } = useContext(CartContext);
  const [isAddToCartSlideOverOpen, setIsAddToCartSlideOverOpen] = React.useState(false);
  const [productItemsForSlideOver, setProductItemsForSlideOver] = React.useState<ProductItem[]>([]);
  const [shopProductsForSlideOver, setShopProductsForSlideOver] = React.useState<ShopProduct[]>();
  const [productDataForSlideOver, setProductDataForSlideOver] = React.useState<ProductData | undefined>();
  const [shopProductVariantsForSlideOver, setShopProductVariantsForSlideOver] = React.useState<ShopProductVariant[]>(
    []
  );

  return (
    <div>
      <button
        data-testid='add-to-cart-button'
        className={classNames('AddToCart__Button', {
          'AddToCart__Button--disabled': !availableForSale,
          'AddToCart__Button--ghost': ghostButton
        })}
        type='button'
        disabled={!availableForSale}
        onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
          e.preventDefault();
          // If user tries to trick with the button, we don't allow to add to cart if not available for sale
          if (availableForSale === false) {
            return;
          }

          // If it's not set, we will try to get it based on all variants of a product
          let calculatedShopProductVariantId: string | undefined = shopProductVariantId;
          let calculatedProductData: ProductData | undefined = productData;

          // If this was called on a "product lister" (ProductResultItem) item, productData is missing, we need to fetch product item data
          // If we are in the AddToCartSlideOver, we already have all the different product information (like productData) and don't need to look it up again
          // Also within the ProductDetailPage, we have all the product information already
          if (!productData && productName && language && siteName) {
            const countryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase() || 'de';
            const productResponse = await getProduct(
              MASTER_PRODUCT_ENDPOINT(productName, language, siteName),
              countryCode,
              language,
              siteName
            );
            const { product, productItems } = productResponse ?? {};

            // If there are product items, we want to lookup all related shop product variants
            if (productItems) {
              const shopifyGlobalIds = getAllShopifyGlobalIdsFromProductItems(productItems);
              const shopProducts = await getShopProductsByVariantGids(shopifyGlobalIds);
              const shopProductsConditionNew = getShopProductsConditionNew(shopProducts);
              let uniqueShopProductVariantsConditionNew =
                getUniqueShopProductVariantsFromShopProducts(shopProductsConditionNew);

              // If we have a defined product variant id (when AddToCart was clicked with a single variant available)
              // We should filter out all other product variants
              if (calculatedShopProductVariantId) {
                uniqueShopProductVariantsConditionNew = uniqueShopProductVariantsConditionNew.filter(
                  productVariant => productVariant.id === calculatedShopProductVariantId
                );
              }

              if (uniqueShopProductVariantsConditionNew === undefined) return;

              if (!calculatedProductData) {
                calculatedProductData = {
                  name: product?.MasterProductENName || '',
                  brand: product?.BrandName || null,
                  category: product?.CategoryEn || null,
                  subcategory: product?.SubCategoryEn || null,
                  masterProductPageUrl: masterProductPageUrl || '',
                  productItemUrl: undefined
                };
              }

              if (uniqueShopProductVariantsConditionNew.length === 0) {
                return;
              }

              // If there is only 1 new variant, we add it directly to the cart (ignoring demo products)
              if (uniqueShopProductVariantsConditionNew.length === 1) {
                calculatedShopProductVariantId = uniqueShopProductVariantsConditionNew[0]?.id;
                calculatedProductData.productVariantId = calculatedShopProductVariantId;
                // If we are coming from the ProductResultItem, the productItemUrl is already prepared, apply it
                if (productItemUrl) {
                  calculatedProductData.productItemUrl = productItemUrl;
                }
              }

              // If there are multiple new variants, we open the slide over showing the available products
              if (uniqueShopProductVariantsConditionNew?.length > 1) {
                setIsAddToCartSlideOverOpen(true);
                setShopProductVariantsForSlideOver(uniqueShopProductVariantsConditionNew);
                setProductItemsForSlideOver(productItems ?? []);
                setProductDataForSlideOver(calculatedProductData);

                if (shopProducts && shopProducts.length > 0) {
                  setShopProductsForSlideOver(shopProducts);
                }
              }
            }
          }

          // If we don't have a single product variant id, we exit here
          if (!calculatedShopProductVariantId) {
            return;
          }
          // If we have a single product variant, we continue here
          if (calculatedProductData) {
            calculatedProductData.productVariantId = calculatedShopProductVariantId;
          }

          // If we don't have any productItemUrl yet, we need to build it
          if (calculatedProductData && !calculatedProductData.productItemUrl && productItem?.articleNumber) {
            // We can do this if we have the masterProductPageUrl
            const calculatedProductItemUrl =
              masterProductPageUrl ?? calculatedProductData.masterProductPageUrl ?? undefined;
            if (calculatedProductItemUrl) {
              // This happens when we are on the AddToCartSlideOver and the productItemUrl is not set yet
              calculatedProductData.productItemUrl =
                calculatedProductItemUrl + '/' + productItem?.articleNumber.replace('.', '-');
            } else {
              // Otherwise we simply take the current URL (eg. if we are on the ProductDetailPage)
              calculatedProductData.productItemUrl = window?.location?.pathname;
            }
          }

          // Make sure that a used device gets the additional attribute in the url
          if (isUsedProduct) {
            if (calculatedProductData?.productItemUrl) {
              calculatedProductData.productItemUrl += '?condition=used';
              debugger;
            }
          }

          // Add the item to the cart
          const addItemResponse = await addItems(
            [calculatedShopProductVariantId],
            quantity,
            calculatedProductData ? [calculatedProductData] : undefined
          );

          if (addItemResponse.cart && typeof addItemResponse.cart !== 'string') {
            const updatedCart = addItemResponse.cart;
            const updatedRow = updatedCart.lines.find(item => item.merchandise.id === calculatedShopProductVariantId);
            const updatedRowIndex = updatedCart.lines.findIndex(
              item => item.merchandise.id === calculatedShopProductVariantId
            );
            const updateRowErrors = addItemResponse.userErrors?.filter(error => +error.field[1] === updatedRowIndex);

            // Check for user errors, like out of stock, or some stock
            if (updateRowErrors.length > 0) {
              dispatch({
                type: ActionType.ERROR_WHEN_ADDING_TO_CART,
                payload: {
                  userErrors: updateRowErrors.map(error => ({
                    message: error.message,
                    field: error.field,
                    variantId: calculatedShopProductVariantId
                  }))
                }
              });
            }

            if (!updatedRow) {
              return;
            }

            dispatch({
              type: ActionType.SET_CART,
              payload: {
                cart: updatedCart
              }
            });

            // Note: The line item contains the product with all variants!
            // So we need to check the correct variant ID to get the price of the added variant
            const priceOfAddedVariant =
              updatedRow.merchandise.product?.variants?.find(variant => variant.id === calculatedShopProductVariantId)
                ?.price.amount ?? '0';
            const addedTotalPrice = (parseFloat(priceOfAddedVariant) * quantity).toFixed(2);

            onAddToCart?.();

            dispatch({
              type: ActionType.OPEN_CART_NOTIFICATION,
              payload: {
                cartItem: {
                  ...updatedRow,
                  cost: {
                    totalAmount: {
                      amount: addedTotalPrice,
                      currencyCode: updatedRow.cost.totalAmount.currencyCode
                    }
                  }
                }
              }
            });
          }
        }}
      >
        {ghostButton ? <ShopIcon24 color='red' size={24} /> : <ShopIcon />}{' '}
        {!hideButtonText && <span className='AddToCart__Button__Text'>{i18n.t('SHOP | Add to cart')}</span>}
      </button>

      {shopProductsForSlideOver ? (
        <AddToCartSlideOver
          productItems={productItemsForSlideOver}
          productData={productDataForSlideOver}
          shopProducts={shopProductsForSlideOver}
          shopProductVariants={shopProductVariantsForSlideOver}
          isOpen={isAddToCartSlideOverOpen}
          onClose={() => setIsAddToCartSlideOverOpen(false)}
          masterProductPageUrl={masterProductPageUrl ?? ''}
        />
      ) : null}
    </div>
  );
}

export default function AddToCart({
  gids,
  shopProductVariants,
  availableForSale,
  productData,
  quantity = 1,
  hideButtonText = false,
  ghostButton = false,
  productName,
  language,
  siteName,
  masterProductPageUrl,
  productItem,
  productItemUrl,
  isUsedProduct = false,
  onAddToCart
}: {
  gids?: string[];
  shopProductVariants?: ShopProductVariant[];
  availableForSale: boolean;
  productData?: ProductData;
  quantity: number;
  hideButtonText?: boolean;
  ghostButton?: boolean;
  productName?: string;
  language?: string;
  siteName?: string;
  masterProductPageUrl?: string;
  productItem?: ProductItem;
  productItemUrl?: string;
  isUsedProduct?: boolean;
  onAddToCart?: () => void;
}): JSX.Element {
  // If we have only 1 gid, we take it as a possible product variant id
  // This should be the case for product lister items, where only 1 item exists, and it should go directly into the cart
  const singleGid = gids?.length === 1 ? gids[0] : undefined;
  const productVariantId = shopProductVariants?.length === 1 ? shopProductVariants[0]?.id : singleGid;

  return (
    <SubmitButton
      availableForSale={availableForSale}
      shopProductVariantId={productVariantId}
      quantity={quantity}
      productData={productData}
      hideButtonText={hideButtonText}
      ghostButton={ghostButton}
      productName={productName}
      language={language}
      siteName={siteName}
      masterProductPageUrl={masterProductPageUrl}
      productItem={productItem}
      productItemUrl={productItemUrl}
      isUsedProduct={isUsedProduct}
      onAddToCart={onAddToCart}
    />
  );
}
