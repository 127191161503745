import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { Visible } from 'react-grid-system';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CloseIcon from '../../../Common/Icons/CloseIcon';
import FacebookIcon from '../../../Common/Icons/FacebookIcon';
import TwitterIcon from '../../../Common/Icons/TwitterIcon';
import LinkedinIcon from '../../../Common/Icons/LinkedinIcon';
import EmailGreyIcon from '../../../Common/Icons/EmailGreyIcon';
import GeneralLink from '../../../Common/GeneralLink';
import WhatsAppIcon from '../../../Common/Icons/WhatsAppIcon';

export class ShareBox extends Component {
  constructor(props) {
    super(props);
    this.state = { currentLink: '' };
  }

  componentDidMount() {
    this.setState({ currentLink: window.location.href });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentLink !== window.location.href) {
      this.setState({ currentLink: window.location.href });
    }
  }

  getShareBoxTextOnMobile(textField) {
    return (
      <Visible sm>
        <Text field={textField} />
      </Visible>
    );
  }

  render() {
    const {
        'Share Card Label': shareCardLabel,
        'Facebook Label': facebook,
        'Twitter Label': twitter,
        'Email Label': emailLabel,
        'Linkedin Label': linkedin,
        'WhatsApp Label': whatsApp,
        'Facebook Link': facebookLink,
        'Twitter Link': twitterLink,
        'WhatsApp Link': whatsAppLink,
        'Linkedin Link': linkedinLink
      } = this.props.fields,
      { isShareOpen, onShareClick, subjectEmail, handleClickOutside } = this.props,
      cssShareClass = classNames('HeroActionButton-ShareBox', {
        'HeroActionButton-ShareBox-Active': isShareOpen
      });

    return (
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <div className={cssShareClass}>
          <div className='HeroActionButton-ShareBox-Heading'>
            <Text field={shareCardLabel} />
            <CloseIcon
              onClick={onShareClick}
              onKeyDown={e => {
                if (e.keyCode === 13) onShareClick();
              }}
              role='button'
              tabIndex='0'
            />
          </div>
          <div className='HeroActionButton-SocialIconsWrapper'>
            <GeneralLink
              fields={{
                value: {
                  href: 'mailto:?subject=' + subjectEmail + '&body=' + this.state.currentLink
                }
              }}
              className='HeroActionButton-ShareLink'
              clickClassName='click_button_share tell_a_friend'
            >
              <EmailGreyIcon />
              {this.getShareBoxTextOnMobile(emailLabel)}
            </GeneralLink>
            <Visible sm md>
              <GeneralLink
                fields={{
                  value: {
                    href: whatsAppLink.value.href + this.state.currentLink
                  }
                }}
                className='HeroActionButton-ShareLink  HeroActionButton-ShareLink-WhatsApp'
                clickClassName='click_button_share whatsapp'
              >
                <WhatsAppIcon />
                {this.getShareBoxTextOnMobile(whatsApp)}
              </GeneralLink>
            </Visible>
            <GeneralLink
              fields={{
                value: {
                  href: facebookLink.value.href + this.state.currentLink,
                  target: 'blank'
                }
              }}
              className='HeroActionButton-ShareLink'
              clickClassName='click_button_share facebook'
            >
              <FacebookIcon />
              {this.getShareBoxTextOnMobile(facebook)}
            </GeneralLink>
            <GeneralLink
              fields={{
                value: {
                  href: twitterLink.value.href + this.state.currentLink,
                  target: 'blank'
                }
              }}
              className='HeroActionButton-ShareLink'
              clickClassName='click_button_share facebook twitter'
            >
              <TwitterIcon />
              {this.getShareBoxTextOnMobile(twitter)}
            </GeneralLink>
            <GeneralLink
              fields={{
                value: {
                  href: linkedinLink.value.href + this.state.currentLink,
                  target: 'blank'
                }
              }}
              className='HeroActionButton-ShareLink'
              clickClassName='click_button_share linkedin'
            >
              <LinkedinIcon />
              {this.getShareBoxTextOnMobile(linkedin)}
            </GeneralLink>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

export default ShareBox;
