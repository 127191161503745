import React from 'react';
import { connect } from 'react-redux';
import ProductsCarousel from '../../ProductsCarousel/index';
import classNames from 'classnames';
import PlayIcon from '../../../Common/Icons/PlayIcon';
import Icon360 from '../../../Common/Icons/Icon360';
import ProductsLightBox from '../../ProductsLightBox/index';
import ProductHeroMainImage from '../../ProductHeroMainImage/index';

/**
 * @method ProductCarouselThumbnailsComponent
 *
 * @description Return a carousel when over 4 images are given or a list of images if bellow
 *
 * @returns jsx
 */
class ProductCarouselThumbnailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      isOpen: false,
      clickedItem: null
    };
  }

  /**
   * @method openLightBox
   * @description set state with the item that have to be oppedned in LightBox
   *
   */
  openLightBox = (itemIndex, item) => {
    document.querySelector('body').style.overflow = 'hidden';
    this.setState({
      activeIndex: itemIndex,
      isOpen: true,
      clickedItem: item
    });
  };
  /**
   * @method closeLightBox
   * @description close lightbox
   *
   */
  closeLightBox = () => {
    document.querySelector('body').style.overflow = 'auto';
    this.setState({ isOpen: false, clickedItem: null });
  };

  /**
   * @method showLightbox
   * @description renders ProductsLightbox Component in the page according to state
   *
   */
  showLightbox = () => {
    if (this.state.clickedItem !== null) {
      const { url, type } = this.state.clickedItem;
      if (this.state.isOpen === true) {
        return (
          <ProductsLightBox
            src={url}
            mediaType={type}
            rightArrowClicked={this.showNextCarouselMediaItem}
            leftArrowClicked={this.showPreviousCarouselMediaItem}
            onClick={this.closeLightBox}
            title={this.props.productTitle}
            allMedias={this.props.media}
            navElementClicked={this.openLightBox}
          />
        );
      }
    }
    return null;
  };

  showNextCarouselMediaItem = () => {
    const nextItemIndex = this.state.activeIndex + 1;
    if (nextItemIndex < this.props.media.length) {
      this.updtateOpacityOnThumbnails(nextItemIndex);
      this.openLightBox(nextItemIndex, this.props.media[nextItemIndex]);
    } else {
      this.updtateOpacityOnThumbnails(0);
      this.openLightBox(0, this.props.media[0]);
    }
  };

  showPreviousCarouselMediaItem = () => {
    const previousItemIndex = this.state.activeIndex - 1;
    if (previousItemIndex >= 0) {
      this.updtateOpacityOnThumbnails(previousItemIndex);
      this.openLightBox(previousItemIndex, this.props.media[previousItemIndex]);
    } else {
      const lastItemIndex = this.props.media.length - 1;
      this.updtateOpacityOnThumbnails(lastItemIndex);
      this.openLightBox(lastItemIndex, this.props.media[lastItemIndex]);
    }
  };

  updtateOpacityOnThumbnails(itemIndex) {
    const allThumbnailsItems = document.querySelectorAll('.ProductCarouselThumbnails-Wrapper-Image');
    if (allThumbnailsItems.length) {
      for (let i = 0; i < allThumbnailsItems.length; i++) {
        if (allThumbnailsItems[i].classList.contains('noOpacityItem' + itemIndex)) {
          allThumbnailsItems[i].style.opacity = 1;
        } else {
          allThumbnailsItems[i].style.opacity = 0.3;
        }
      }
    }
  }
  updateItemIndex = (itemIndex, mainItem, isMobile) => {
    if (!isMobile) {
      this.updtateOpacityOnThumbnails(itemIndex);

      if (this.state.isOpen) {
        this.openLightBox(itemIndex, mainItem);
      } else {
        this.setState({ activeIndex: itemIndex });
      }
    }
  };

  getThumbnails = isMobile => {
    const { selectedVariantMedia, media } = this.props,
      mediaToDisplay = selectedVariantMedia && selectedVariantMedia.length ? selectedVariantMedia : media,
      nrOfItemsInCarousel = 12;

    if (mediaToDisplay.length) {
      const numberOfMediaItems = this.state.isOpen ? mediaToDisplay.length : nrOfItemsInCarousel,
        itemsToMap = mediaToDisplay.slice(0, numberOfMediaItems);

      return itemsToMap.map((item, index) => {
        const imageClass = classNames('ProductCarouselThumbnails-Wrapper-Image noOpacityItem' + index, {
          noOpacity: index === 0
        });
        const imageSrc = item.thumbnail;
        let imageToShow = (
          <img className={imageClass} src={imageSrc + '?imwidth=100'} alt={item.alt || null} loading='lazy' />
        );
        if (isMobile) {
          imageToShow = (
            <img
              className={imageClass}
              sizes='(min-width: 320px) and (max-width: 767px) 727px,(min-width: 768px) and (max-width: 959px) 919px,1180px'
              srcSet={`${imageSrc}?imwidth=320 320w, ${imageSrc}?imwidth=768 768w, ${imageSrc}?imwidth=960 960w, ${imageSrc}?imwidth=1440 1440w`}
              src={imageSrc}
              alt={item.alt || null}
              loading='lazy'
            />
          );
        }
        return (
          <div
            key={imageSrc + index}
            role='button'
            tabIndex='0'
            className='ProductCarouselThumbnails-Wrapper'
            onClick={() => this.openLightBox(index, item)}
            onKeyDown={e => {
              if (e.keyCode === 13) this.openLightBox(index, item);
            }}
            onMouseOver={() => this.updateItemIndex(index, item, isMobile)}
            onFocus={() => this.updateItemIndex(index, item, isMobile)}
          >
            {item.type === 'video/mp4' ? <PlayIcon /> : null}
            {item.type === '3Dobject' ? <Icon360 /> : null}
            {imageSrc ? imageToShow : null}
          </div>
        );
      }, this);
    }
  };

  getContent = () => {
    const { media, isMobile } = this.props;
    if (media.length > 4 || isMobile) {
      return (
        <ProductsCarousel isLightBoxOpen={this.state.isOpen} isMobile={isMobile}>
          {this.getThumbnails(isMobile)}
        </ProductsCarousel>
      );
    }
    return <div className='ProductCarouselThumbnails-Container'>{this.getThumbnails()}</div>;
  };

  render() {
    if (!this.props.media || !this.props.media.length) {
      return null;
    }
    return (
      <React.Fragment>
        {!this.props.isMobile ? (
          <ProductHeroMainImage
            imageItem={this.props.media[this.state.activeIndex]}
            mainImageClicked={() => {
              this.openLightBox(this.state.activeIndex, this.props.media[this.state.activeIndex]);
            }}
          />
        ) : null}
        <div className='ProductCarouselThumbnails'>
          {this.showLightbox()}
          {this.getContent()}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { masterProductData, selectedVariant } = state.productDetails;
  return {
    media: masterProductData ? masterProductData.media : null,
    productTitle: masterProductData ? masterProductData.MasterProductName : null,
    selectedVariantMedia: selectedVariant ? selectedVariant.media : null
  };
};

export default connect(mapStateToProps)(ProductCarouselThumbnailsComponent);
