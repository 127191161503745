import React from 'react';
import i18n from 'i18next';
import { Col, Container, Row } from 'react-grid-system';
import PriceDetails from '../../PriceDetails/PriceDetails';
import ChangeQuantityButton from '../../Cart/EditItemQuantityButton/ChangeQuantityButton';
import { ShopProductVariantWithProduct } from '../../lib/shopify/types';
import BundleItem from '../BundleItem';
import Button from '../../../Common/Button/Button';
import classNames from 'classnames';
import AddBundleToCart from '../../AddToCart/AddBundleToCart';
import { ProductItemTechnicalDetails } from '../../../Product/types';

export default function BundleDisplay({
  items,
  bundleName,
  baseProductVariant,
  bundleDiscountPercentage,
  technicalDetails
}: {
  items: ShopProductVariantWithProduct[];
  bundleName: string;
  baseProductVariant: ShopProductVariantWithProduct;
  bundleDiscountPercentage: number;
  technicalDetails: ProductItemTechnicalDetails[];
}): JSX.Element {
  const [showMoreBundleItems, setShowMoreBundleItems] = React.useState(false);
  const toggleMoreBundleItems = () => {
    setShowMoreBundleItems(!showMoreBundleItems);
  };

  const minAdditionalBundleItems = 2;
  const mainBundleItem = items.find(item => item.id === baseProductVariant.id) ?? baseProductVariant;
  const additionalBundleItems = items.filter(item => item.id !== baseProductVariant.id);
  const amountOfMoreBundleItems = additionalBundleItems.length - minAdditionalBundleItems;
  const hasMoreBundleItems = amountOfMoreBundleItems > 0;

  // Calculate the total price of the bundle
  const totalPriceOriginal = items.reduce((acc, item) => acc + parseInt(item.price.amount), 0);
  const currencyCode = items[0].price.currencyCode;

  // Display the right amount of additional bundle items
  const additionalBundleItemsRender = additionalBundleItems
    .slice(0, showMoreBundleItems ? undefined : minAdditionalBundleItems)
    .map((item, i) => (
      <BundleItem item={item} type='additional' key={item.id} technicalDetail={getTechnicalDetail(item.sku)} />
    ));

  const [quantity, setQuantity] = React.useState<number>(1);

  function lowerQuantity() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  function increaseQuantity() {
    setQuantity(quantity + 1);
  }

  function getTechnicalDetail(sku: string | null = ''): ProductItemTechnicalDetails | undefined {
    return technicalDetails.find(detail => detail.articleNumber === sku);
  }

  return (
    <div className='BundleDisplay-Container'>
      <Container className='BundleDisplay' fluid>
        <Row>
          <Col className='BundleDisplay__BundleName'>{bundleName}</Col>
        </Row>
        <div
          className={classNames('BundleDisplay__ItemContainer', {
            'BundleDisplay__ItemContainer--ShowOnlyFirstItems': !showMoreBundleItems
          })}
        >
          <BundleItem item={mainBundleItem} type='main' technicalDetail={getTechnicalDetail(mainBundleItem.sku)} />
          {additionalBundleItemsRender}
        </div>

        {hasMoreBundleItems && (
          <Row align='center' justify='center'>
            <Button
              className='BundleDisplay__ShowMoreButton'
              data-testid='bundle-display-has-more-items'
              onClick={toggleMoreBundleItems}
              buttonTitle={
                showMoreBundleItems
                  ? i18n.t('SHOP | Show less bundle items')
                  : i18n.t('SHOP | Show more bundle items', { amount: amountOfMoreBundleItems })
              }
              beforeIcon={showMoreBundleItems ? 'icon-web-16-minus' : 'icon-web-16-plus'}
              variant='text'
            />
          </Row>
        )}

        <Row align='end' justify='between'>
          <Col className='BundleDisplay__PriceText' xs={12} style={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
            <span>
              {i18n.t('SHOP | Bundle Price Text', {
                productTitle: mainBundleItem.product.title,
                sku: mainBundleItem.sku
              })}
            </span>
          </Col>
          <Col className='BundleDisplay__FooterCol' xs={12}>
            <Row nogutter className='BundleDisplay__FooterCol__PriceDetailsRow' align='end' justify='between'>
              <Col width='content'>
                <PriceDetails
                  variant='bundle'
                  currentPriceAmount={undefined}
                  forceDiscountPercentage={bundleDiscountPercentage}
                  oldPriceAmount={`${totalPriceOriginal}`}
                  currencyCode={currencyCode}
                  showOldPrice={true}
                  showDiscountTag={true}
                />
              </Col>
              <Col width='content'>
                <div className='AddToCart__Line'>
                  <div className='CartItem__quantity'>
                    <ChangeQuantityButton type='minus' clicked={lowerQuantity} />
                    <p className='CartItem__quantity-text'>
                      <span data-testid='bundle-item-quantity-text' className='CartItem__quantity-value'>
                        {quantity}
                      </span>
                    </p>
                    <ChangeQuantityButton type='plus' clicked={increaseQuantity} />
                  </div>

                  <AddBundleToCart
                    productVariants={items}
                    quantity={quantity}
                    discountCode={bundleName}
                    technicalDetails={technicalDetails}
                    totalPriceOriginal={totalPriceOriginal}
                    currencyCode={currencyCode}
                    bundleDiscountPercentage={bundleDiscountPercentage}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
