import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import InfoIcon from '../../Common/Icons/InfoIcon';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';

const METRIC = 'metric';
const IMPERIAL = 'imperial';

class TehnicalSpecifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      system: METRIC
    };
  }

  onSystemClick = value => {
    this.setState({
      system: value
    });
  };

  getInfoTooltip(tooltip) {
    if (tooltip && tooltip.trim() !== '') {
      return (
        <div className='TehnicalSpecifications-Row-Name-Info'>
          <InfoIcon />
          <span className='TehnicalSpecifications-Row-Name-Info-Tooltip'>{tooltip}</span>
        </div>
      );
    }
  }
  generateSpecs = () => {
    return this.props.technicalSpecs[this.state.system].map((item, index) => {
      const cssClass = classNames('TehnicalSpecifications-Row', {
        GrayBackground: index & 1 ? true : false
      });

      return (
        <div key={index + item.name} className={cssClass}>
          <div className='TehnicalSpecifications-Row-Name'>
            {item.name}
            {this.getInfoTooltip(item.tooltip)}
          </div>
          <div className='TehnicalSpecifications-Row-Value'>{item.value}</div>
        </div>
      );
    });
  };

  generateHeadDetails = () => {
    const { Title, 'Metric Label': Metric, 'Anglo-American Label': AngloAmerican } = this.props.fields;

    const cssMetricClass = classNames('TehnicalSpecifications-Head-Systems-Metric', {
      'TehnicalSpecifications-Head-Systems-Metric-Active': this.state.system === METRIC
    });
    const cssImperialClass = classNames('TehnicalSpecifications-Head-Systems-Imperial', {
      'TehnicalSpecifications-Head-Systems-Imperial-Active': this.state.system === IMPERIAL
    });
    return (
      <div className='TehnicalSpecifications-Head'>
        <HeadlineWithTitleAndSubtitle title={Title} />
        <div className='TehnicalSpecifications-Head-Systems'>
          <div
            className={cssMetricClass}
            role='button'
            tabIndex='0'
            onKeyDown={e => {
              if (e.keyCode === 13) this.onSystemClick(METRIC);
            }}
            onClick={() => {
              this.onSystemClick(METRIC);
            }}
          >
            {Metric.value}
          </div>
          <div
            className={cssImperialClass}
            role='button'
            tabIndex='0'
            onKeyDown={e => {
              if (e.keyCode === 13) this.onSystemClick(IMPERIAL);
            }}
            onClick={() => {
              this.onSystemClick(IMPERIAL);
            }}
          >
            {AngloAmerican.value}
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (!hasItems(this.props)) {
      return null;
    }
    return (
      <div className='TehnicalSpecifications'>
        <div className='TehnicalSpecifications-Wrapper componentContainer'>
          {this.generateHeadDetails()}
          {this.generateSpecs()}
        </div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  return {
    // The technicalSpecs should represent the technical specs of the selected variant if it exists, otherwise the master product data.
    technicalSpecs:
      state.productDetails?.selectedVariant?.technicalSpecs ??
      state.productDetails?.masterProductData?.technicalSpecs ??
      null
  };
};

const hasItems = function (props) {
  const { technicalSpecs, fields } = props;
  if (!technicalSpecs || !technicalSpecs.metric || !technicalSpecs.metric.length || !fields) {
    return false;
  }
  return true;
};

export default connect(MapStateToProps)(WithStickyMenu(TehnicalSpecifications, hasItems));
