//get cookie
import { DEFAULT_COOKIES_DATA, COOKIE_NECESSARY, WISHLIST, COMPARE } from '../CookieSettings/CookieConstants';

export function getCookie(name) {
  const allowedCookie = shouldSetGetCookie(name);

  if (!allowedCookie && DEFAULT_COOKIES_DATA[name]) {
    return DEFAULT_COOKIES_DATA[name];
  }

  if (typeof document !== 'undefined') {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  return null;
}

//set cookie
export function setCookie(name, value, days) {
  const allowedCookie = shouldSetGetCookie(name);

  if (!allowedCookie) {
    return;
  }

  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString();
  }
}

export function getLocalStorage(name) {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(name);
  }

  return null;
}

/*
 * cookiePolicyToCheck - preferences | necessary | statistics | marketing
 */
export function setLocalStorage(name, value, cookiePolicyToCheck = 'preferences') {
  if (typeof window !== 'undefined' && window.Cookiebot) {
    const { localStorage, Cookiebot } = window;

    // Only verify the cookie policy if it is not a necessary cookie
    if (cookiePolicyToCheck === 'necessary' || Cookiebot.consent[cookiePolicyToCheck]) {
      localStorage.setItem(name, value);
    }
  }
}

export function getSessionStorage(name) {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(name);
  }

  return null;
}

export function setSessionStorage(name, value) {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(name, value);
  }
}
export function deleteSessionStorage(name) {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem(name);
  }
}

//delete cookie
export function deleteCookie(name) {
  setCookie(name, '', -1);
}

// This function only allows synchronous calls to get the cookie,
// otherwise we would have to change everything to async.
// It works if the consent is already given, but it could lead to issues, if the consent is not available yet.
// Very early in the page loading process, there is a chance, that cookiebot is not yet loaded and we don't know the cookie consent.
// For such scenarios we might need to think about a different approach, but its probably an edge case.
export function shouldSetGetCookie(name) {
  const { necessary } = COOKIE_NECESSARY;
  // Note: Repeated checking of cookies could be improved with caching the result of it, or making them an object instead of array
  // Necessary cookies can always be set and get, no need to check for consent on cookiebot
  if (necessary.some(item => item === name)) {
    return true;
  }

  // Other cookies can only be set and get if the user has given consent
  if (typeof window !== 'undefined' && window?.Cookiebot?.consent) {
    return !!window.Cookiebot.consent.statistics;
  }

  return false;
}

export function setProductToWishlistOnCookie(wishlistName, productTitle, productId) {
  const obj = {
    MasterProductName: productTitle,
    VariantId: productId,
    AddDate: new Date()
  };
  const wishlistCookie = JSON.parse(getCookie(WISHLIST)),
    wishlist = wishlistCookie ? wishlistCookie : {};
  if (wishlist && wishlist[wishlistName]) {
    const productsList = wishlist[wishlistName],
      checkProductId = productsList.find(item => item.VariantId === productId);
    if (!checkProductId && productId !== 0) {
      productsList.push(obj);
      wishlist[wishlistName] = productsList;
      setCookie(WISHLIST, JSON.stringify(wishlist), 365);
    }
  } else {
    wishlist[wishlistName] = [obj];
    setCookie(WISHLIST, JSON.stringify(wishlist), 365);
  }
}
export function removeProductFromWishlistCookie(wishlistName, productId) {
  const cookieWishlistData = getCookie(WISHLIST) ? JSON.parse(getCookie(WISHLIST)) : {},
    currentWishlist = cookieWishlistData[wishlistName];

  if (currentWishlist && currentWishlist.length > 0) {
    const newWishlist = currentWishlist.filter(item => item.VariantId !== productId);

    if (newWishlist.length > 0) {
      cookieWishlistData[wishlistName] = newWishlist;
      setCookie(WISHLIST, JSON.stringify(cookieWishlistData), 365);
    } else {
      deleteCookie(WISHLIST);
    }
  }
}

export function setProductToCompareOnCookie(category, productId) {
  const compareListCookie = JSON.parse(getCookie(COMPARE)),
    compareList = compareListCookie ? compareListCookie : {},
    categoryList = category.split(',');
  categoryList.map(element => compareProductCategory(compareList, element, productId));
}

export function compareProductCategory(list, productCategory, id) {
  if (list && list[productCategory]) {
    const productsList = list[productCategory],
      checkProductId = productsList.find(item => item === id);
    if (!checkProductId && productsList.length < 4 && id !== 0) {
      productsList.push(id);
      list[productCategory] = productsList;
      setCookie(COMPARE, JSON.stringify(list), 30);
      dispatchChangeOnCookieEvent();
    }
  } else {
    let idList = [];
    idList.push(id);
    list[productCategory] = idList;
    setCookie(COMPARE, JSON.stringify(list), 30);
    dispatchChangeOnCookieEvent();
  }
}

export function removeProductFromCompareCookie(productId) {
  const compareListCookie = JSON.parse(getCookie(COMPARE)),
    compareListCategories = compareListCookie ? Object.keys(compareListCookie) : [],
    newlist = {};
  if (compareListCategories && compareListCategories.length) {
    compareListCategories
      .map(item => {
        const ramainingProducts = compareListCookie[item].filter(element => +element !== +productId);
        if (ramainingProducts.length) {
          return (newlist[item] = ramainingProducts);
        }
        return null;
      })
      .filter(element => element !== null);
    setCookie(COMPARE, JSON.stringify(newlist), 30);
    dispatchChangeOnCookieEvent();
  }
}

function dispatchChangeOnCookieEvent() {
  const cookieEvent = new CustomEvent('changedCompareStateOnCookies');
  document.dispatchEvent(cookieEvent);
}

export function removeCategoryFromCompareCookie(category) {
  const compareListCookie = JSON.parse(getCookie(COMPARE)),
    currentCompareListId = compareListCookie[category];
  if (currentCompareListId) {
    currentCompareListId.map(item => removeProductFromCompareCookie(item));
  }
}

export function getRawValueFromCompare() {
  const compareData = JSON.parse(getCookie(COMPARE));

  if (compareData) {
    return [].concat.apply([], Object.values(compareData));
  }

  return [];
}
