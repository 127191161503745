import React, { SVGProps } from 'react';
import { IconNames } from './newIcons-types';

export type IconColor =
  | 'red'
  | 'green'
  | 'orange'
  | 'gray'
  | 'white'
  | 'black'
  | 'black-80'
  | 'black-60'
  | 'black-30'
  | 'inherit'; // Defined colors should match up with ".sprite-icon" in Icons.scss
export type IconSize = 8 | 16 | 24 | 32 | 40 | 80;
export type IconPropsOnIconWrapper = SVGProps<SVGSVGElement> & Partial<IconProps>;
export type IconProps = SVGProps<SVGSVGElement> & {
  name: IconNames;
  color?: IconColor;
  className?: string;
  size?: IconSize;
  hoverIcon?: boolean;
};

const newIconFilename = '/svg/newIcons/newIcons.svg';

/**
 * This component is responsible for rendering the icons. Every icon rendering should get its own icon component, to define its defaults.
 */
export const Icon = (props: IconProps): JSX.Element => {
  const { size, name, hoverIcon = false, className = '', color = '', ...rest } = props;
  const colorClass = color ? `sprite-icon--${color}` : '';
  const iconClassName = `sprite-icon ${colorClass} svg-icon--${name}`;
  const fileName = newIconFilename;
  const inlineStyle = { ...props.style, width: size, height: size };
  const hoverIconClass = hoverIcon ? 'hover-icon' : '';

  // Determine the sitecoreDistPath value based on the environment
  let sitecoreDistPath = typeof window !== 'undefined' ? window?.app?.sitecoreDistPath : '/dist/leister-app';

  // we could remove the following dev check, if the sitecoreDistPath would be set to "" for "npm run start" process
  // TODO: @Dave: please help/check if this is possible
  if (process && process?.env?.NODE_ENV === 'development') {
    sitecoreDistPath = '';
  }
  const iconXlinkHref = `${sitecoreDistPath}${fileName}#${name}`;

  return (
    <svg {...rest} className={`${iconClassName} ${className} ${hoverIconClass}`} style={inlineStyle}>
      <use xlinkHref={iconXlinkHref} />
    </svg>
  );
};

export default Icon;
