import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Placeholder, VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import {
  getLocalStorage,
  setLocalStorage,
  deleteSessionStorage,
  setCookie
} from './components/Common/CookieSettings/CookieUtils';
import { getLanguage } from './components/Common/CookieSettings/SetLanguageData';
import { isPageEditor, checkIfUserIsLoggedIn } from './utils/Utils';
import './assets/app.scss';
import { REDIRECT_PAGE } from './Constants/General';
import { PRODUCT_PAGE_EXACT_ROUTE } from './AppRoutes';
import { OmniboxContextProvider } from './components/Coveo/context/OmniboxContext';
import { SearchContextProvider } from './components/Coveo/context/SearchContext';
import { SearchEngineContextProvider } from './components/Coveo/context/SearchEngineContext';
import { CoveoSiteNames } from './components/Coveo/CoveoSettings';
import FloatingCartToggle from './components/Shopify/FloatingCartToggle';
import { SITE_LEISTER_TECHNOLOGIES, renderShop } from './AppGlobals';
import Cookies from 'js-cookie';
import {
  SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME,
  COUNTRY_CODE,
  SITE_NAME,
  ONE_YEAR
} from './components/Common/CookieSettings/CookieConstants';
import { triggerHashScroll } from './utils/scrollHelpers';
import { isProductDownloadPage } from './components/Product/AllDownloads/getDownloadsProductAndArticle';

const HERO_NAMES = [
  'SolutionHero',
  'UniversalHero',
  'ExpertiseHero',
  'UniversalHero',
  'CareerHero',
  'StoryHero',
  'HeroStory',
  'HeroCareer'
];

const getMetaObject = (mainPlaceholder, route) => {
  const heroComponent = mainPlaceholder.find(item => HERO_NAMES.includes(item.componentName));
  if (
    heroComponent &&
    heroComponent.fields &&
    heroComponent.fields.Image &&
    heroComponent.fields.Image.value &&
    heroComponent.fields.Image.value.src
  ) {
    return heroComponent.fields.Image.value.src;
  } else if (
    route &&
    route.fields &&
    route.fields['Meta Image'] &&
    route.fields['Meta Image'].value &&
    route.fields['Meta Image'].value.src
  ) {
    return route.fields['Meta Image'].value.src;
  }
};
class Layout extends React.Component {
  state = {
    interfacesIds: [],
    shouldRenderShop: false
  };

  constructor(props) {
    super(props);
    this.state.location = props.location.pathname;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.shouldRenderShop !== nextState.shouldRenderShop) {
      return true;
    }
    if (this.props.route === nextProps.route) {
      return false;
    }

    return true;
  }

  setCurrentPageLocation() {
    const previousPageTitle = getLocalStorage('previousPageTitle'),
      titlePage = this.props.route.fields.Title.value;

    if (previousPageTitle !== titlePage) {
      setLocalStorage('currentPageTitle', titlePage, 'necessary');
      setLocalStorage('currentPageLocation', this.props.location.pathname || '/', 'necessary');
      setLocalStorage('currentPageId', this.props.route.itemId || null, 'necessary');
    }
  }

  setPreviousPageLocation(pageTitle, pageUrl, pageId) {
    setLocalStorage('previousPageTitle', pageTitle, 'necessary');
    setLocalStorage('previousPageLocation', pageUrl, 'necessary');
    setLocalStorage('previousPageId', pageId, 'necessary');
  }
  setCurrentWithPrevious() {
    const currentPageTitle = getLocalStorage('currentPageTitle'),
      currentPageLocation = getLocalStorage('currentPageLocation'),
      currentPageId = getLocalStorage('currentPageId');

    if (currentPageLocation === null) {
      this.setCurrentPageLocation();
    } else if (currentPageLocation !== this.props.location.pathname && currentPageId !== this.props.route.itemId) {
      this.setPreviousPageLocation(currentPageTitle, currentPageLocation, currentPageId);
      this.setCurrentPageLocation();
    }
  }

  removeContactFromSessionStorage() {
    const { match } = this.props;
    if (match && match.params && match.params.sitecoreRoute && match && match.params) {
      if (!match.params.sitecoreRoute.toLowerCase().includes('contact')) {
        deleteSessionStorage('contactPointInfoId');
        deleteSessionStorage('person');
      }
    }
  }

  async componentDidMount() {
    const { route, sitecoreContext } = this.props;
    const { site } = sitecoreContext;
    const siteName = site?.name || SITE_LEISTER_TECHNOLOGIES;

    setCookie(SITE_NAME, siteName, ONE_YEAR);

    if (route.displayName !== REDIRECT_PAGE) {
      this.setCurrentWithPrevious();
    }
    this.triggerPageViewEvent();

    this.removeContactFromSessionStorage();

    this.getStoreRendering(siteName);

    this.handleHashChange();
  }

  handleHashChange() {
    // If we have a hash, scroll already to it
    if (window?.location?.hash.indexOf('=') === -1) {
      triggerHashScroll();
    }

    // Check for hash changes and act accordingly
    window.addEventListener('hashchange', triggerHashScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', triggerHashScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const { route } = this.props;

    this.triggerPageViewEvent(prevProps);

    if (
      this.props.location.pathname !== prevState.location &&
      window.location.href.indexOf('#') < 0 &&
      this.props.history.action === 'PUSH'
    ) {
      window.scrollTo(0, 0);
    }

    if (route.displayName !== REDIRECT_PAGE) {
      this.setCurrentWithPrevious();
    }
    this.removeContactFromSessionStorage();

    this.setState({ location: this.props.location.pathname });
  }

  getStoreRendering = async siteName => {
    const countryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase();
    const shopifyTestAccessKey = Cookies.get(SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME);
    const shouldRenderShop = await renderShop(countryCode, shopifyTestAccessKey, siteName);
    this.setState({ shouldRenderShop });
  };

  triggerPageViewEvent = prevProps => {
    const { route } = this.props,
      pageUrl = document.location.origin + document.location.pathname + document.location.search,
      pageTitle = (route.fields && route.fields['Page Title'] && route.fields['Page Title'].value) || 'Page',
      prevPageTitle =
        prevProps &&
        prevProps.route &&
        prevProps.route.fields &&
        prevProps.route.fields['Page Title'] &&
        prevProps.route.fields['Page Title'].value;
    if (!pageUrl.includes(PRODUCT_PAGE_EXACT_ROUTE)) {
      if (!prevProps || !prevProps.location) {
        window.dataLayer.push({
          event: 'pageview',
          page: {
            url: pageUrl,
            title: pageTitle
          }
        });
      } else if (prevProps && prevProps.location && pageTitle !== prevPageTitle) {
        window.dataLayer.push({
          event: 'pageview',
          page: {
            url: pageUrl,
            title: pageTitle
          }
        });
      }
    }
  };

  render() {
    const {
        route,
        route: {
          fields: { 'Is Page User Restricted': IsPageUserRestricted }
        },
        sitecoreContext,
        location
      } = this.props,
      currentLanguage = getLanguage(sitecoreContext),
      contentPageMetaImage = getMetaObject(route.placeholders['jss-main'], route),
      pageTitle = (route.fields && route.fields['Page Title'] && route.fields['Page Title'].value) || 'Page',
      pageDescription =
        (route.fields && route.fields['Page Description'] && route.fields['Page Description'].value) || '';

    if (
      !isPageEditor() &&
      IsPageUserRestricted &&
      IsPageUserRestricted.value &&
      !checkIfUserIsLoggedIn(sitecoreContext)
    ) {
      return <Redirect to={'/'} />;
    }

    const { coveo, site } = sitecoreContext;
    const siteName = site?.name || SITE_LEISTER_TECHNOLOGIES;
    const coveoSiteName = CoveoSiteNames[siteName];
    const shouldRenderShop = this.state.shouldRenderShop;
    const downloadProductPageRoute = sitecoreContext?.productDownloads?.productDownloadsDetailPage || '';
    const isDownloadPage = isProductDownloadPage(location.pathname, downloadProductPageRoute);

    return (
      <SearchContextProvider>
        <SearchEngineContextProvider
          locale={currentLanguage}
          searchToken={coveo.searchToken}
          coveoSiteName={coveoSiteName}
          siteName={siteName}
        >
          {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
          {location && location.pathname && !location.pathname.includes(PRODUCT_PAGE_EXACT_ROUTE) && !isDownloadPage && (
            <Helmet htmlAttributes={{ lang: currentLanguage }}>
              {pageTitle && <title>{pageTitle}</title>}
              {pageDescription && <meta name='description' content={pageDescription} />}
              {pageTitle && <meta property='og:title' content={pageTitle} />}
              {pageDescription && <meta property='og:description' content={pageDescription} />}
              {contentPageMetaImage && <meta property='og:image' content={contentPageMetaImage} />}

              {typeof window !== 'undefined' && <meta property='og:url' content={window.location.href} />}

              {sitecoreContext.site.name && <meta property='og:site_name' content={sitecoreContext.site.name} />}
              {route.fields && route.fields.NoIndex && route.fields.NoIndex.value === true ? (
                <meta name='robots' content='noindex' />
              ) : (
                ''
              )}
            </Helmet>
          )}
          {/*
					  VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
					  If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
					  For XM (CMS-only) apps, this should be removed.

					  VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
					*/}
          <VisitorIdentification />

          {/* root placeholder for the app, which we add components to using route data */}
          <OmniboxContextProvider>
            <nav>
              <div className='container'>
                <Placeholder name='jss-menu' rendering={route} />
              </div>
            </nav>
          </OmniboxContextProvider>
          <div className='container grid-container jss-main-container'>
            <Placeholder name='jss-main' rendering={route} />
            {shouldRenderShop && <FloatingCartToggle />}
          </div>
          <footer>
            <div className='footer-container'>
              <Placeholder name='jss-footer' rendering={route} />
            </div>
          </footer>
        </SearchEngineContextProvider>
      </SearchContextProvider>
    );
  }
}

export default withRouter(withSitecoreContext()(Layout));
