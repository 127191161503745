import React, { ComponentType, useEffect } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProductVariantSelector, { ProductVariantSelectorData } from '../../Shopify/ProductVariantSelector';
import { Column } from '../../Common/Table';
import { ShopProduct } from '../../Shopify/lib/shopify/types';
import LinkButton from '../../Common/LinkButton/LinkButton';
import ListLayoutIcon from '../../Common/Icons/ListLayout';
import { ProductItem } from '../types';
import { getSpec } from '../productItem';
import {
  filterProductsBySkuAndReduceProductVariants,
  getShopProductsConditionNew,
  getShopProductsConditionUsed
} from '../../Shopify/lib/shopify/leisterHelpers';
import { getIsConditionUsedFromUrl } from '../../Shopify/lib/utils';
import { getBundleInfoFromProductItems, hasProductItemBundle } from './VariantSelectorFunctions';

type VariantsSelectorProps = {
  productItems: ProductItem[];
  defaultProductItems?: ProductItem[];
  shopProducts?: ShopProduct[];
  currentArticleNumber?: string;
  masterProductPageUrl: string;
  history: RouteComponentProps['history'];
  triggerText?: string;
};

const VariantSelector: React.FC<VariantsSelectorProps> = ({
  productItems,
  defaultProductItems,
  shopProducts = [],
  currentArticleNumber,
  masterProductPageUrl,
  history,
  triggerText
}) => {
  const [variantSelectorOpen, setVariantSelectorOpen] = React.useState(false);
  const [variantSelectorData, setVariantSelectorData] = React.useState<ProductVariantSelectorData[]>([]);
  const [columns, setColumns] = React.useState<Column<ProductVariantSelectorData>[]>([]);
  const [productItemsState, setProductItemsState] = React.useState<ProductItem[]>([]);
  const onConditionUsed = getIsConditionUsedFromUrl();

  function isProductHighlighted(productItem: ProductItem, used = false) {
    return productItem.articleNumber === currentArticleNumber && (onConditionUsed || false) === used;
  }

  // defaultProductItems are ones sent on component mount, productItems are ones from redux
  useEffect(() => {
    if (productItems && productItems.length >= 1) {
      setProductItemsState(productItems);
    } else if (defaultProductItems && defaultProductItems.length >= 1) {
      setProductItemsState(defaultProductItems);
    }
  }, [productItems, defaultProductItems]);

  React.useEffect(() => {
    let mounted = true;

    async function getVariantsData() {
      if (mounted && productItemsState && productItemsState.length >= 1) {
        const filteredProductVariantBundleData = await getBundleInfoFromProductItems(productItemsState);

        if (!mounted) {
          return;
        }

        const columnDef: Column<ProductVariantSelectorData>[] = [
          {
            key: 'articleNumber',
            title: i18n.t('SHOP | Article number'),
            renderer: 'bold-text',
            sortBy: 'alphabetical'
          }
        ];

        productItemsState[0].filterValues.forEach(filter => {
          columnDef.push({
            key: filter.name,
            title: filter.name,
            renderer: 'text',
            sortBy: 'alphabetical'
          });
        });

        columnDef.push({
          key: 'availability',
          title: i18n.t('SHOP | Availability label'),
          renderer: 'availability',
          sortBy: 'numerical'
        });
        columnDef.push({
          key: 'price',
          title: i18n.t('SHOP | Price label'),
          renderer: 'price',
          sortBy: 'numerical',
          textAlign: 'right'
        });

        setColumns(columnDef);
        const data: ProductVariantSelectorData[] = [];

        productItemsState.forEach(productItem => {
          const tags = [];
          // there should be only one not used product with the same sku
          const shopProductsWithCurrentSku = filterProductsBySkuAndReduceProductVariants(
            shopProducts,
            productItem?.articleNumber
          );
          const shopProductConditionNew = getShopProductsConditionNew(shopProductsWithCurrentSku)[0]; // There should be only 1 now anyway;
          const shopProductsConditionUsed = getShopProductsConditionUsed(shopProductsWithCurrentSku);
          const shopProductVariantConditionNew = shopProductConditionNew?.variants[0]; // There should be only 1 now anyway;

          const filterColumns = productItem.filterValues.reduce((acc, filter) => {
            acc[filter.name] = filter.value;

            return acc;
          }, {} as Record<string, string>);

          if (hasProductItemBundle(filteredProductVariantBundleData, productItem)) {
            tags.push(i18n.t('SHOP | Bundle tag'));
          }

          data.push({
            id: productItem.id,
            highlighted: isProductHighlighted(productItem, false),
            isInOnlineShop: !!shopProductVariantConditionNew?.id,
            articleNumber: productItem.articleNumber,
            ...filterColumns,
            shopProduct: shopProductConditionNew as ShopProduct,
            shopProductVariant: shopProductVariantConditionNew,
            tags,
            url: `${masterProductPageUrl}/${productItem.articleNumber.replace(/\./g, '-')}`,
            techSpec: getSpec(productItem)
          });

          if (shopProductsConditionUsed.length) {
            shopProductsConditionUsed.forEach(shopProductUsed => {
              const shopProductVariantUsed = shopProductUsed.variants.find(v => v.sku === productItem?.articleNumber);
              data.push({
                id: productItem.id,
                highlighted: isProductHighlighted(productItem, true),
                isInOnlineShop: !!shopProductVariantUsed?.id,
                articleNumber: productItem.articleNumber,
                ...productItem.filterValues.reduce((acc, filter) => {
                  acc[filter.name] = filter.value;

                  return acc;
                }, {} as Record<string, string>),
                shopProduct: shopProductUsed as ShopProduct,
                shopProductVariant: shopProductVariantUsed,
                tags: [i18n.t('SHOP | Demo device tag')],
                url: `${masterProductPageUrl}/${productItem.articleNumber.replace(/\./g, '-')}`,
                techSpec: getSpec(productItem)
              });
            });
          }
        });

        setVariantSelectorData(data);
      }
    }

    getVariantsData();

    return () => {
      mounted = false;
    };
  }, [productItemsState, currentArticleNumber, masterProductPageUrl, shopProducts]);

  const amountOfProductItems = productItemsState.length;
  const amountOfShopProductVariants = shopProducts.filter(
    shopProduct => shopProduct?.condition?.value !== 'used'
  ).length;

  // Display the variant selector only if we have more than one product item or more than one shop product variant
  const displayVariantSelector = amountOfProductItems > 1 || amountOfShopProductVariants > 1;

  return displayVariantSelector && variantSelectorData?.length >= 1 ? (
    <div className='VariantSelector'>
      {!triggerText && (
        <div className='VariantSelector__text'>
          <span>{i18n.t('SHOP | Variant selector variants text', { count: productItemsState.length })}</span>
          {shopProducts.length > 0 ? (
            <span>
              , {shopProducts.filter(shopProduct => shopProduct?.condition?.value !== 'used').length}{' '}
              {i18n.t('SHOP | Variant selector variants in shop')}
            </span>
          ) : null}
        </div>
      )}
      <div>
        <LinkButton
          beforeIcon={<ListLayoutIcon />}
          title={triggerText || i18n.t('SHOP | Variant selector show all')}
          onClick={() => setVariantSelectorOpen(true)}
          withArrow={false}
          size='small'
        />
        <ProductVariantSelector
          data={variantSelectorData}
          columns={columns}
          isOpen={variantSelectorOpen}
          onClose={() => setVariantSelectorOpen(false)}
          history={history}
        />
      </div>
    </div>
  ) : null;
};

const MapStateToProps = (state: any) => {
  const productItems = state.productDetails.variants;

  return {
    productItems: productItems ? productItems : null
  };
};

export default connect(MapStateToProps)(
  withRouter<RouteComponentProps & VariantsSelectorProps, ComponentType<RouteComponentProps & VariantsSelectorProps>>(
    VariantSelector
  )
);
